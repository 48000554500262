.kategori-container {
  display: grid;
  gap: 16px;
  padding: 32px;
}

.kategori-row {
  display: grid;
  gap: 16px;
}

.kategori-row:first-child {
  grid-template-columns: 1fr 1fr;
  /* First row with two equal-width columns */
}

.kategori-row:nth-child(2) {
  grid-template-columns: 1fr 2fr 1fr;
  /* Second row with larger center column */
}

.kategori-row:nth-child(3) {
  grid-template-columns: 1fr 1fr 2fr;
}

.kategori-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 0;
  margin: 0;
}

.kategori-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Image Styling */
.kategori-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Add a pseudo-element for the gradient effect */
.kategori-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(29, 22, 21, 0.3),
    rgba(61, 46, 43, 0.3)
  );
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease;
  z-index: 1;
}

/* Show the gradient on hover */
.kategori-item:hover::before {
  opacity: 1;
}

/* Image zoom effect */
.kategori-item:hover .kategori-image {
  transform: scale(1.1);
}

/* Category Name Styling */
.kategori-name {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  font-size: 30px;
  font-weight: 900;
  color: white;
  opacity: 0;
  text-align: center;
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 2; /* Ensure text is above the gradient */
}

/* Fade-in text on hover */
.kategori-item:hover .kategori-name {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

/* Hide kate-name on hover */
.kate-name {
  display: block;
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  transform: scale(0.8);
  transition: all 0.2s ease;
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

.kategori-item:hover .kate-name {
  transform: scale(0.8); /* Keep the scale effect */
  opacity: 0; /* Fade out on hover */
  visibility: hidden; /* Hide the element */
}

@media (max-width: 768px) {
  .kategori-name h6{
    font-size: 16px;
  }

  .kategori-name {
    font-size: 16px;
  }

  .kategori-container {
    padding: 0px;
  }

  .kategori-row:first-child {
    display: grid;
    grid-template-columns: 1fr; /* mobile: single column */

    .kategori-image {
      width: 368px;
      height: 172px;
      object-fit: cover;
      display: block;
    }
  }

  .kategori-row:nth-child(2) {
    grid-template-columns: 1fr 1fr;
    /* First row with two equal-width columns */
  }

  .kategori-row:nth-child(3) {
    grid-template-columns: 1fr;

    .kategori-image {
      width: 368px;
      height: 172px;
      object-fit: cover;
      display: block;
    }
  }

  .kategori-row:nth-child(4) {
    grid-template-columns: 1fr 1fr;
  }

  .kategori-item {
    width: 100%;
  }

  .kategori-image {
    width: 172px;
    height: 172px;
    object-fit: cover;
    display: block;
  }
}

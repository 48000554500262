.grid-container {
    margin-top: 116px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
}

.brand-card {
    background-color: white;
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.brand-card:hover {
    transform: scale(1.05);
}

.brand-image {
    max-width: 100%;
    max-height: 100%;
    width: 480px;
    height: 273px;
    border-radius: 8px;
    object-fit: cover;

}
